<template>
  <div>
    <NuxtLoadingIndicator
      color="var(--brand-color)"
      :height="4"
      :throttle="200"
    />
    <vPreNav />
    <vNav />
    <!-- <vBar /> -->
    <main>
      <slot />
    </main>
    <vFooter />
    <vPostFooter />
    <vCart />
    <vToasts />
    <vSvgSprite />
  <!-- <Support v-if="!auth.isAdmin" /> -->
  </div>
</template>

<style scoped>
#__nuxt {
  display: grid;
  grid-template-rows: 32px 56px  auto auto;
  min-height: 100%;
  transition: transform 256ms ease;
  -webkit-tap-highlight-color: transparent;
}
</style>
